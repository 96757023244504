<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="2"></top>
      <div class="container-box copyrightRegistration flex-column justify-content-start align-items-center">
        <div class="width-100 flex-row justify-content-start align-items-center border-b">
          <p class="fs-super-big black fw-mid">{{ $route.query.key == 'bank' ? '银行入驻申请' : '律所入驻申请' }}</p>
        </div>
        <div class="width-60 form-box">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item :label="$route.query.key == 'bank' ? '银行名称' : '律所名称'" prop="name">
              <el-input v-model="form.name" :placeholder="$route.query.key == 'bank' ? '请输入银行名称' : '请输入律所名称'"></el-input>
            </el-form-item>
            <el-form-item :label="$route.query.key == 'bank' ? '银行法人' : '律所法人'" prop="legalPerson">
              <el-input v-model="form.legalPerson" :placeholder="$route.query.key == 'bank' ? '请输入银行法人' : '请输入律所法人'"></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contacts">
              <el-input v-model="form.contacts" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="mobile">
              <el-input v-model="form.mobile" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item v-if="$route.query.key == 'bank'" label="服务范围" prop="range">
              <el-select v-model="form.range" placeholder="请选择服务范围">
                <el-option label="服务范围1" value="1"></el-option>
                <el-option label="服务范围2" value="2"></el-option>
                <el-option label="服务范围3" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-else label="服务范围" prop="range">
              <el-select v-model="form.range" placeholder="请选择服务范围">
                <el-option label="服务范围1" value="1"></el-option>
                <el-option label="服务范围2" value="2"></el-option>
                <el-option label="服务范围3" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$route.query.key == 'bank' ? '服务内容' : '律所简介'" prop="desc">
              <el-input
                type="textarea"
                v-model="form.desc"
                :autosize="{ minRows: 10, maxRows: 15 }"
                :placeholder="$route.query.key == 'bank' ? '可以提供的服务详细说明' : '关于律所的详细简介'"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$route.query.key == 'bank' ? '银行资料' : '律所资料'" prop="logo">
              <div class="width-100 flex-row justify-content-start align-items-star">
                <el-upload
                  class="avatar-uploader"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="form.logo" :src="form.logo" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="margin-l">
                  <p class="darkgrey opacity-8 line-height2 margin-l">{{ $route.query.key == 'bank' ? '银行logo' : '律所logo' }}</p>
                  <p class="darkgrey opacity-8">（不超过2M）</p>
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="other">
              <div class="width-100 flex-row justify-content-start align-items-star">
                <el-upload
                  class="avatar-uploader"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="form.other" :src="form.logo" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="margin-l">
                  <p class="darkgrey opacity-8 line-height2 margin-l">其他资料</p>
                  <p class="darkgrey opacity-8">（可上传营业执照等资料）</p>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { legalLawFirmsSave } from '@/api/TranLegalLawFirms';
export default {
  name: 'applyingForResidency',
  components: {
    top,
    bottom
  },
  data() {
    return {
      checked: false,
      dialogVisible: false,
      search: '',
      form: {
        name: '', //名称
        legalPerson: '', //法人
        contacts: '', //联系人
        mobile: '', //联系方式
        range: '', //范围
        desc: '', //内容
        logo: '', //logo图片
        other: '' //其他资料
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        legalPerson: [{ required: true, message: '请输入法人', trigger: 'blur' }],
        contacts: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        range: [{ required: true, message: '请选择范围', trigger: 'change' }],
        desc: [
          {
            required: true,
            message: '请填写内容',
            trigger: 'blur'
          }
        ],
        logo: [{ required: true, message: '请上传logo' }],
        other: [{ required: true, message: '请上传其他资料' }]
      }
    };
  },
  created() {},
  methods: {
    onSubmit() {
      console.log('submit!');
      legalLawFirmsSave(this.form).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: '您的入驻申请已提交，请耐心等待审核！',
            type: 'success'
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    margin: 40px 0;
    padding: 30px;
    border-radius: 10px;
    background: white;
    .icon-img {
      width: 48px;
      margin-left: 20px;
    }
    .form-box {
      padding: 60px 0;
    }
  }
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form-item__label,
.el-input__inner,
.el-radio__label,
.el-textarea__inner,
.el-form-item__error {
  font-size: 18px;
}
.copyrightRegistration .el-input__inner {
  border: 1px solid #dcdfe6 !important;
  height: 3rem;
}
.copyrightRegistration .el-form-item {
  margin-bottom: 40px;
}

.copyrightRegistration .el-form-item__content {
  margin-left: 150px !important;
}
.copyrightRegistration .el-select {
  width: 100%;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio {
  padding-left: 0 !important;
  padding-right: 25px !important;
  margin-right: 10px !important;
}

.copyrightRegistration .el-button--primary {
  padding: 20px 80px;
}
.copyrightRegistration .el-button--primary span {
  font-size: 20px;
}
</style>
